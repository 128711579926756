<template>
	<li
		v-if="Array.isArray(item) && !item.children"
		class="filter-item group"
	>
		<strong v-if="$vnode.key !== 'undefined'">
			{{ $vnode.key }}
		</strong>
		<ul>
			<FilterItem
				v-for="el in item"
				:key="el.slug"
				:current="current"
				:item="el"
				:icon="icon"
				@change="onChange"
			/>
		</ul>
	</li>

	<li
		v-else-if="isCurrent"
		class="filter-item is-current"
	>
		<BaseButton
			icon="ui/arrow-left.svg"
			:title="'Zurück'"
			:click="() => onChange(parent || item)"
		>
			{{ item.name }}
		</BaseButton>

		<ul>
			<FilterItem
				v-for="el in item.children"
				:key="el.slug"
				:current="current"
				:item="el"
				:icon="icon"
				@change="onChange"
			/>
		</ul>
	</li>

	<li
		v-else
		:class="['filter-item', {'is-active': isCurrent}]"
	>
		<BaseButton
			:icon="(icon && item.slug) ? `filter/${item.slug}.svg` : ''"
			:title="`Nach ${item.name} filtern`"
			:click="() => onChange(item)"
		>
			<span class="label">
				<strong>
					{{ item.name }}
					<template v-if="item.year">({{ item.year }})</template>
				</strong>
				<span v-if="item.alias">{{ item.alias }}</span>
			</span>
			<span
				v-if="item.count"
				class="count"
			>
				{{ item.count }}
			</span>
		</BaseButton>
	</li>
</template>

<script>
import FilterItem from './FilterItem';


export default {
	name: 'FilterItem',
	components: {
		FilterItem
	},
	props: {
		current: {
			type: [String, Object],
			default: ''
		},
		item: {
			type: [Array, Object],
			required: true
		},
		icon: {
			type: Boolean,
			default: false
		},
		parent: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		isCurrent() {
			const {item: {name, year}, current} = this;
			return (year) ? (`${name} (${year})` === current) : (name === current);
		}
	},
	methods: {
		onChange(item) {
			this.$emit('change', item);
		}
	}
};
</script>

<style lang="scss">
	.filter-item {
		line-height: 1.5;

		&:hover > button,
		button:focus {
			background-color: $color-light;
		}

		&.is-active > button {
			background-color: $color-border !important;
		}

		> strong {
			display: block;
			padding: 0.5rem 1rem;

			> button {
				display: inline-block;
				padding: 0.5rem;
				width: auto;
			}
		}

		> button {
			display: flex;
			align-items: center;
			padding: 0.5rem;
			line-height: inherit;

			> span {
				display: flex;
				flex: 1;
				align-items: center;
				font-weight: normal;
			}

			> svg {
				flex: 0 0 auto;
			}
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}

		&.is-current {
			> strong {
				display: flex;
				align-items: center;

				> button {
					flex: 0 0 auto;
					line-height: 0;
				}
			}
		}

		+ .group {
			border-top: 1px solid $color-border;
		}

		.label {
			flex: 1;
			padding: 0 0.5rem;

			> span {
				display: block;
				font-size: 0.75rem;
			}
		}

		.count {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			background-color: $color-light;
			border-radius: 50%;
			font-size: 0.6875rem;
		}
	}
</style>
